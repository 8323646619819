.container {
    width: 100%;
    height: 60vh; 
    background-image: url("../../Assets/HomeImage.jpg");
    background-size: cover;
    background-position: center; 
    background-repeat: no-repeat; 
    margin-top: -22px;
}

.hero{
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
   justify-content: center;  
   /* background-color: aqua; */
}

.hero p{
    font-size: 25px;
    font-weight: 500;
    line-height: 24.615px;
    font-family: fira sans;
    margin-bottom: -15px;
    color: #1f5156;
}
.hero h1{
    font-size: 34px;
    font-family: Exo two;
    font-weight: 700;
    line-height: 1.1;
    text-align: center;
    color: #1f5156;
    
}




@media (max-width: 768px) {
    .container {
        height: 70vh;
        width: 100%;
        background-image: url("../../Assets/phnhero.jpeg");
        background-position: 70% center;
        display: flex;
        justify-content: center;
    }

    .hero{
        width: 100%;
        height: 85%;
        
    }
    .hero h1{
        font-size: 30px;
    }
}