/* Testimonials.css */

.container-testimonial {
    height: 40vh;  
  }
  
  .testimonials-wrapper {
    margin: auto 50px;
    display: flex; 
    justify-content: space-between; 
    align-items: center; 
  }

  .image-wrapper{
  }
  
  .image-wrapper img {
    border-radius: 10px;
    height: 300px;
    width: 500px;
  }
  
  .slider {
  }

  @media (max-width: 800px){

    .container-testimonial {
        height: 70vh;  
      }


    .testimonials-wrapper {
        flex-direction: column;
        margin: auto 10px;
        height: 90%;
    }

    .image-wrapper img {
        border-radius: 10px;
        height: 270px;
        width: 100%;
      }
  }

  @media (max-width: 1280px){

    .image-wrapper img {
        height: 270px;
        width: 400px;
      }
  }
  