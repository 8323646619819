.slider-container {
    width: 60vw;
    margin: 0 auto;
    overflow: hidden;
    background-color: antiquewhite;
    border-radius: 10px;
  }
  
  .slider-content {
    text-align: center;
    padding: 20px;
  }
  
  .slider-content h2 {
    font-size: 24px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .slider-content p {
    font-size: 18px;
    color: #666;
    font-style: italic;
  }

  .dash-image{
    height: 22px;
    width: 22px;
    margin-bottom: 8px;
  }

  @media (max-width: 800px){
    .slider-container {
      width: 99vw !important;
      }
      .slider-content p {
        font-size: 1px;
      }
  }

  @media (max-width: 1280px){

    .slider-content p {
        font-size: 24px;
      }

      .slider-container{
        width: 58vw;
      }
  }
  