.image-style {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  border-radius: 10px; 
  max-width: 100%; 
  max-height: 100%; 
}

.swiper-slide{
    aspect-ratio: 1.2 / 0.8;
    object-fit: cover;
}

.image-style3 {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  border-radius: 10px; 
  max-width: 100%; 
  max-height: 100%; 
}

.image-style7 {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  border-radius: 10px; 
  max-width: 100%; 
  max-height: 100%; 
}
