.container-gallery{
    margin-top: 80px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 100px;
    text-align: center;
}

.container-gallery h4{
    font-size: 4em;
    font-weight: 500;
    font-family: Exo Two;
    line-height: 1.2;
    letter-spacing: 0px;
}