.card-wrapper {
  height: 450px;
  width: 300px;
  padding-top: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: box-shadow 0.3s ease;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
}

.maincard {
  height: 95%;
  width: 100%;
}

.button {
  margin-top: -15px;
  margin-bottom: 25px;
}

.card-wrapper:hover {
  box-shadow: 0 12px 18px rgba(0, 0, 0, 0.2);
}

.title {
  font-family: fira sans;
  line-height: 1.4px;
  font-size: 22px;
  margin-bottom: 10px;
}

.card-wrapper p {
  font-family: fira sans;
  line-height: 24.615px;
  padding-left: 8px;
  padding-right: 8px;
}

.card-wrapper img {
  height: 50%;
  width: 85%;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.card-wrapper img:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .card-wrapper {
    height: auto;
    width: 90%;
    max-width: 320px;
    margin: 20px auto;
    padding: 20px;
  }

  .card-wrapper img {
    height: 40%;
    width: 100%;
  }

  .title {
    font-size: 24px;
    font-weight: 800;
  }

  .card-wrapper p {
    margin-top: 15px;
    font-size: 18px;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 20px;
  }

  .card-wrapper p {
    font-size: 16px;
  }
}
